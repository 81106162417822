/* Footer.css */
.footer-container {
    padding: 20px;
    background: #120136; /* Dark background for the footer */
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .social-links {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
  }
  
  .social-icon {
    font-size: 24px; /* Adjust size as needed */
    color: #fff;
    transition: transform 0.3s ease-in-out;
  }
  
  .social-icon:hover {
    transform: translateY(-5px);
    color: #9b59b6; /* Purple shade for hover state */
  }
  
  .copy-info {
    font-size: 14px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      padding: 10px;
    }
  
    .social-icon {
      font-size: 20px; /* Smaller icons on mobile */
    }
  
    .social-links {
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }
  
    .copy-info {
      font-size: 12px; /* Slightly smaller text on mobile */
    }
  }
  
  