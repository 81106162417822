.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #4b134f, #3b0848);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

.title {
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.nft-display {
  max-width: 600px;
  margin-bottom: 20px;
}

.nft-display img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.button-groups {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Space between buttons */
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
}

.link-style {
  text-decoration: none;
  display: flex;
}

.home-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: 2px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  color: white;
  transition: all 0.3s ease;
  text-align: center;
  flex: 1;
}

.home-button:hover {
  background-color: #fff;
  color: #4b134f;
}

@media (max-width: 768px) {
  .button-groups {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .home-button {
    width: 100%;
  }
}

.project-description {
  text-align: center;
  max-width: 800px;
  margin: 0;
}

.project-description h1, .project-description p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .title {
    font-size: 20px;
    line-height: 1.2;
    padding: 0 20px;
  }
}

@media (min-width: 768px) {
  .home-container {
    padding: 40px;
  }

  .project-description h1 {
    font-size: 1.2rem;
    text-align: center;
  }

  .project-description p {
    font-size: 1.1rem;
  }
}
