.mint-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - var(--navbar-height));
  padding-top: var(--navbar-height, 60px);
  background: linear-gradient(to bottom, #4b134f, #3b0848);
  background-size: cover;
  background-position: center;
  padding-bottom: 20px;
}

.mint-type-select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.mint-type-select-container label {
  font-size: 18px;
  color: #f0eaeb;
  margin-bottom: 8px;
}

.mint-type-select {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 3;
  position: relative;
}

.mint-content {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.3);
  margin-top: -10px;
  z-index: 2;
  position: relative;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
}

.mint-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: 24px;
  color: #f0eaeb;
  font-weight: bold;
}

.mint-image {
  width: 100%;
  max-width: 320px;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.view-nfts-button {
  background-color: #E94057;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease-in-out;
}

.view-nfts-button:hover {
  background-color: #F27121;
}

.mint-info, .mint-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 10px;
}

.mint-info p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
  color: #FFF;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.mint-info p label {
  margin-right: 10px;
  font-weight: bold;
  color: #E94057;
}

.mint-info p:last-child {
  margin-bottom: 20px;
}

.mint-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.mint-action input,
.mint-action button {
  height: 40px;
  padding: 8px 12px;
  border-radius: 5px;
}

.mint-action input {
  width: auto;
  flex-grow: 1;
  max-width: 100px;
}

.mint-action button {
  white-space: nowrap;
  background-color: #E94057;
  color: #FFF;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
  padding: 10px 20px;
}

.mint-action button:hover {
  background-color: #F27121;
}

.popup {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 10;
  display: flex;
  gap: 10px;
  align-items: center;
}

.popup a {
  color: #4fa;
}

.mint-popup {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #540d6e;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.mint-popup a {
  color: #9cf;
  text-decoration: none;
}

.mint-popup a:hover {
  color: #ccf;
  text-decoration: underline;
}

.mint-popup-close {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
}

.images-with-text {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.image-and-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-and-text img {
  max-width: 100px;
  height: auto;
}

.image-and-text p {
  margin-top: 8px;
}

@media (max-width: 768px) {
  .images-with-text {
    flex-direction: column;
  }
}

.used-hounds-list {
  margin-top: 6px;
  color: #FFF;
  text-align: center;
  /* font-weight: bold; */
}