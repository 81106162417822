.mint-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - var(--navbar-height));
  padding-top: var(--navbar-height, 60px);
  background: linear-gradient(to bottom, #4b134f, #3b0848);
  background-size: cover;
  background-position: center;
  padding-bottom: 20px;
}

.mint-content {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.3);
  margin-top: -20px;
  z-index: 2;
  position: relative;

}

.mint-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: 24px; /* Adjust size as needed */
  color: #f0eaeb; /* Same pinkish hue as the STAKE header */
  font-weight: bold; 
}


.mint-image {
  width: 100%;
  max-width: 320px;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}



.mint-info, .mint-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 10px;
}

.mint-info p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
  color: #FFF;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.mint-info p label {
  margin-right: 10px;
  font-weight: bold;
  color: #E94057;
}

.mint-info p:last-child {
  margin-bottom: 20px; /* Increase the bottom margin to create more space */
}

.mint-action {
  display: flex;
  flex-direction: row; 
  justify-content: center; /* Center the flexbox items horizontally */
  align-items: center; /* Align items vertically in the center */
  gap: 10px; /* Space between the input and the button */
}

.mint-action input,
.mint-action button {
  height: 40px; /* Set a fixed height for both input and button */
  padding: 8px 12px; /* Padding to make them visually appealing */
  border-radius: 5px; /* Rounded corners for aesthetics */
}

.mint-action input {
  width: auto; /* Allow the input to size according to its content */
  flex-grow: 1; /* Let it grow to fill the space, but up to a limit */
  max-width: 100px; /* Set a max-width for the input field */
}

/* Mint button style */
.mint-action button {
  white-space: nowrap;
  background-color: #E94057;
  color: #FFF;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
  padding: 10px 20px; /* Keep padding consistent */
  /* No need for width: auto here as button size is controlled by padding and content */
}



.mint-action button:hover {
  background-color: #F27121;
}

.popup {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 10; /* Ensure it's above other content */
  display: flex;
  gap: 10px;
  align-items: center;
}

.popup a {
  color: #4fa;
}

/* Popup styling */
.mint-popup {
  position: fixed; /* Fixed positioning to stay in view */
  bottom: 20px; /* Positioned at the bottom of the screen */
  left: 50%; /* Center the popup */
  transform: translateX(-50%); /* Adjust centering with transform */
  background-color: #540d6e; /* Dark background for contrast */
  color: white; /* White text for readability */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  z-index: 10; /* Ensure it's above all other content */
}

.mint-popup a {
  color: #9cf; /* This is a light cyan color that should be visible against the purple background */
  text-decoration: none; /* Optional: Removes underline from the link */
}

.mint-popup a:hover {
  color: #ccf; /* A slightly different shade for hover for better interaction visibility */
  text-decoration: underline; /* Optional: Adds underline on hover */
}

/* Close button styling */
.mint-popup-close {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
}


.images-with-text {
  display: flex;
  justify-content: center;
  gap: 20px; /* adjust the space between the images as necessary */
}

.image-and-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-and-text img {
  max-width: 100px; /* adjust this as necessary */
  height: auto;
}

.image-and-text p {
  margin-top: 8px; /* adjust as necessary */
}

/* Adjust the width of the images for responsiveness */
@media (max-width: 768px) {
  .images-with-text {
    flex-direction: column;
  }
}
