.nft-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.75); */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: flex-start;
  padding-top: 10vh;
}

.modal-content {
  width: 100%; /* Adjust the width as needed */
  max-height: 80vh; /* Maximum height to allow scrolling */
  overflow-y: auto; /* Enable scrolling if content overflows */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px; /* Add space between header and gallery */
}

.close-button {
  background-color: white; /* White background */
  color: black; /* Black font color */
  border: none;
  border-radius: 50%; /* Makes it circular */
  font-size: 16px; /* Maintains font size */
  padding: 8px; /* Sufficient padding to make it circular */
  width: 30px; /* Fixed width */
  height: 30px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto; /* Pushes other buttons to the right */
  cursor: pointer;
  /* Add a subtle shadow if you like */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.close-button:hover {
  background-color: #e6e6e6; /* Light grey background on hover for some interaction feedback */
  /* Optionally change the color on hover if desired */
}
/* Adjust the size of other buttons by changing their padding and font-size */
.selection-controls button, 
.stake-button {
  font-size: 14px; /* Smaller font size for other buttons */
  padding: 5px 10px; /* Adjust padding to make buttons smaller */
  /* Other styles... */
}

.selection-controls {
  display: flex;
  gap: 10px;
}

.stake-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.nft-item.selected {
  border-color: blue; /* Highlight color when selected */
}

.checkmark {
  font-size: 0.8rem; /* Adjust the size of the checkmark */
  position: absolute;
  top: 5px;
  right: 5px;
}



.nft-image {
  max-width: 100%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  
}


.nft-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Responsive grid columns */
  gap: 16px; /* Space between cards */
  color: black;
}

.nft-item {
  background: white;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers children horizontally */
  justify-content: center; /* Centers children vertically */
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.nft-item img {
  max-width: 100px; /* Limits image width */
  height: auto;
  margin-bottom: 8px; /* Space below the image */
}

.nft-item p {
  text-align: center; /* Center-align text */
  margin: 0; /* Remove default margins */
  color: black; /* Text color */
}

.nft-name, .nft-rewards {
  text-align: center;
  margin: 0;
  color: black;
  font-size: 14px; /* Set the font size to 14px, adjust as needed */
}

.nft-item p {
  text-align: center;
  margin: 0;
  color: black;
  font-size: 14px; /* This sets the font size for all <p> within .nft-item */
}

/* Default font size for desktops */
.nft-name, .nft-rewards {
  font-size: 14px; /* Larger font size for desktop */
}

.nft-name {
  font-weight: bold; /* Make the name bold */
}

/* Adjust font size for tablets */
@media (max-width: 768px) {
  .nft-name, .nft-rewards {
    font-size: 12px; /* Slightly smaller font size for tablets */
  }
}

/* Adjust font size for mobile devices */
@media (max-width: 480px) {
  .nft-name, .nft-rewards {
    font-size: 10px; /* Smallest font size for mobile devices */
  }
}


.nft-item img {
  max-width: 100px; /* Adjust max-width for a smaller image */
  height: auto;
  margin-bottom: 8px; /* Adjust spacing between image and text */
}

.nft-name {
  color: black; /* Ensure this is black or any other visible color against the background */
  z-index: 100
}