.bridging-container {
  text-align: center;
  padding: 20px 20px; /* Reduced padding */
  max-width: 800px;
  margin: 0 auto;
  background: #290B45;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 2.5em;
  margin-bottom: 10px; /* Reduced margin */
  color: #ffffff;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Reduced gap */
}

.step {
  text-align: center;
  color: #ffffff;
  padding: 10px 20px; /* Reduced padding */
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
  background-color: #372563;
}

.step-header {
  font-size: 1em;
  font-weight: bold;
  color: #ff6200;
  margin-bottom: 10px; /* Reduced margin */
}

.chain-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.chain-selection label {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2em;
}

.chain-selection select {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
}

.arrow {
  font-size: 1.5em;
  margin: 0 10px;
}

.input-container {
  margin: 10px 0; /* Reduced margin */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.bridging-button, .open-modal-button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  background-color: #ff6200;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  transition: background-color 0.3s;
}

.bridging-button:hover, .open-modal-button:hover {
  background-color: #ff8533;
}

.status-section {
  margin-top: 20px;
  color: #ffffff;
  text-align: center;
}

.view-transaction {
  margin-top: 5px; /* Reduced margin */
  font-size: 0.9em; /* Smaller font size */
  color: #ffffff; /* White color */
}

.view-transaction a {
  color: #ffffff;
  text-decoration: none;
}

.view-transaction a:hover {
  text-decoration: underline;
}
