body {
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, #4b134f, #3b0848); /* Background to match the container */
  font-family: 'Arial', sans-serif;
  color: white;
  
}

.mint-popup {
  position: fixed; /* Fixed positioning to stay in view */
  bottom: 20px; /* Positioned at the bottom of the screen */
  left: 50%; /* Center the popup */
  transform: translateX(-50%); /* Adjust centering with transform */
  background-color: #540d6e; /* Dark background for contrast */
  color: white; /* White text for readability */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  z-index: 1000; /* Ensure it's above all other content */
}

.popup {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1000; /* Ensure it's above other content */
  display: flex;
  gap: 10px;
  align-items: center;
}

.popup a {
  color: #4fa;
}

/* Close button styling */
.mint-popup-close {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.staking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Ensure everything is centered vertically */
  background: linear-gradient(to bottom, #4b134f, #3b0848);
  padding: 20px;
  font-family: 'Arial', sans-serif;
  border-radius: 15px;
  margin: 2rem auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #FFF;
  width: 80%; /* Set a max width */
  max-width: 1200px; /* And a maximum width */
  border: 2px solid rgba(255, 255, 255, 0.5); /* White border with 50% transparency */
}


.status-indicators {
  display: flex;
  justify-content: space-around;
  align-items: center; /* Align the indicators */
  width: 100%;
  /* Add space between rows */
}

* {
  box-sizing: border-box;
}


.indicator {
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(5px);
  color: #FFF;
  padding: 15px; /* Slightly more padding for a square-ish look */
  border-radius: 10px;
  flex: 1 1 20%; /* Grow to fill space, starting at 20% of the container's width */
  margin: 0.5rem; /* Consistent margin around indicators */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 150px; /* Minimum width */
  min-height: 80px; /* Minimum height for consistency */
  text-align: center;
  font-size: 0.85rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.claim-all {
  /* Apply same styles as other indicators */
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  color: #FFF;
  border-radius: 10px;
  padding: 15px;
  margin: 0.5rem;
  font-size: 0.85rem;
  flex: 1 1 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  min-height: 80px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.indicator img {
  max-width: 50px; /* Control the size of icons */
  margin-right: 10px; /* Space between icon and text */
}

.cards {
  display: flex;
  justify-content: space-around; /* This will space the cards equally */
  align-items: flex-start; /* Align the tops of the cards */
  width: 100%;
  gap: 1rem; /* Add gap between cards */
  margin-top: 1rem; /* Space from the above row */
  
}

.card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 1rem;
  border-radius: 10px;
  flex: 1 1 calc(50% - 4rem); /* Take half the space minus the gap */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.3);
  max-width: 350px; /* Maximum width to avoid too wide cards */
  display: flex;
  flex-direction: column; /* Organize the content vertically */
  justify-content: space-between; /* Distribute space between elements */
  height: 100%; /* Ensure cards take full height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  
}

.card-actions {
  display: flex;
  flex-direction: column; /* Stack the buttons vertically */
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}
.large-font-button {
  font-size: 1.1rem; /* Adjust the size as needed */
}

.image-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.image-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-text img {
  max-width: 100%; /* adjust as necessary */
  height: auto; /* maintain aspect ratio */
  
}

.image-text p {
  margin-top: 8px; /* adjust as necessary */
}


.card-content h2 {
  color: #E94057; /* Pinkish hue for headings */
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center; /* Align text and other elements in the center */
  width: 100%; /* Take up the full width of the card for the content */
  
}

.card-content img {
  display: block; /* Images are inline by default, block will take the full width */
  margin: 10px auto; /* Auto margins on the side to center the image */
  max-width: 40%; /* Adjust the max width as necessary, keeping the image responsive */
  width: 200px; /* Fixed width */
  height: auto;
}


/* Apply to all buttons inside .staking-container, without affecting other buttons */
.staking-container .full-width-button {
  width: 100%; /* Make the button full-width */
  
}

/* Other button styles */
.staking-container button {
  background-color: #E94057; /* Match the minting page button color */
  color: #FFF;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card-content p,
.card-content select,
.card-content button {
  width: 100%; /* Make select and button full width */
  margin-top: 10px; /* Add some space at the top */

  text-align: center; /* Center the text of all children */
  /* Other styles as necessary */
}

.contract-selector {
  margin-bottom: 20px; /* Space below the dropdown */
  text-align: center; /* Center the dropdown */
  width: 100%; /* Full width */
  display: flex;
  justify-content: center; /* Center the dropdown */
}

/* Label styling */
.contract-selector label {
  font-size: 1rem; /* Adjust font size */
  color: #FFF; /* White color for label */
  margin-right: 10px; /* Space between label and dropdown */
  align-self: center; /* Center vertically */
}

/* Dropdown styling */
.contract-selector select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  color: #FFF; /* White text */
  font-size: 1rem;
  outline: none; /* Remove default outline */
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Dropdown hover effect */
.contract-selector select:hover {
  background: rgba(255, 255, 255, 0.4); /* Slightly more opaque on hover */
}

/* Dropdown option styling */
.contract-selector select option {
  background: rgba(75, 19, 79, 1); /* Match the background */
  color: #FFF; /* White text */
}

@media (max-width: 768px) {
  .contract-selector {
    flex-direction: column;
    align-items: center;
  }

  .contract-selector label {
    margin-right: 0; /* Remove right margin on mobile */
    margin-bottom: 10px; /* Add bottom margin */
  }
}

button:hover {
  background-color: #F27121; /* Orangish hue on hover */
}

@media (max-width: 768px) {
  .status-indicators, .cards {
    flex-direction: column;
  }

  .indicator, .card {
    width: 100%; /* Full width for small screens */
    margin: 10px 0; /* Add some vertical space */
  }

  .indicator {
    min-height: 30px; /* Reduced min-height for a more compact look on mobile */
    padding: 8px 10px; /* Adjust padding to reduce overall height but maintain content padding */
    font-size: 0.75rem; /* Optionally reduce font size to fit smaller content area */
  }
}



.staking-stats-header {
  font-size: 24px; /* Adjust size as needed */
  color: #E94057; /* Same pinkish hue as the STAKE header */
  font-weight: bold; /* Make the font bold */
  text-align: center; /* Center the text */
  margin-top: 5px; /* Space at the top */
  margin-bottom: 5px; /* Space at the bottom */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

}
