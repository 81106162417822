/* Import Roboto font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Base styles */
/* Your existing CSS styles go here */

nav {
  font-family: 'Roboto', sans-serif;
  color: #FFFFFF;
  background: linear-gradient(270deg, #8A2387, #E94057, #F27121);
  animation: AnimationName 16s ease infinite;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px; /* Maintain padding for spacing */
}

.logo, .hamburger, .eth-balance-container, .connect-wallet {
  margin: 5px; /* Added margin for spacing */
}

.logo {
  font-size: 1.5em; /* Or choose a size that fits your design */
  font-weight: bold; /* Make the text bold */
  cursor: pointer; /* Indicates that the logo is clickable */
  text-decoration: none; /* Removes underline from link */
  color: inherit; /* Ensures the color inherits from the parent */
}

.logo:hover {
  transform: scale(1.05); /* Slightly enlarges the logo on hover */
}



/* Animation for gradient background */
@keyframes AnimationName {
  0% { background-position: 0% 51%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 51%; }
}

/* Top row styles */
.top-row {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligns items to the left */
}

/* Bottom row styles */
.bottom-row {
  display: flex;
  justify-content: flex-end; /* Aligns items to the right */
  align-items: center;
}

.wallet-section {
  display: flex;
  align-items: center;
  position: relative;
}


.wallet-options {
  position: absolute;
  right: 20px;
  top: 60px; /* Adjust based on the navbar height */
  background-color: #2c3e50; /* Dark background for the dropdown */
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  padding: 10px;
  z-index: 100; /* Ensure it's above other content */
}

.mobile-menu li {
  margin-bottom: 10px;
}

/* Adjust mobile menu item text color */
.mobile-menu li a {
  color: #FFFFFF;
}

.wallet-section {
  position: relative; /* Ensures the absolute positioning of child elements is relative to this container */
}

.connect-wallet {
  position: relative; /* Ensures the button is properly positioned in the flow */
  z-index: 2; /* Higher than the options to potentially help with stacking context */
}

.wallet-options ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.wallet-options ul li {
  padding: 8px 12px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}

.wallet-options ul li:hover {
  background-color: #34495e; /* Lighter shade on hover */
}

.wallet-options ul li a {
  color: white;
  text-decoration: none;
  
}

.eth-balance-container {
  background-color: #444;
  border-radius: 20px;
  padding: 10px 20px; /* Adjust padding to match the connect button */
  display: flex; /* To align text vertically */
  align-items: center; /* Aligns the text vertically in the center */
  justify-content: center; /* Centers the content horizontally */
  font-size: 0.8rem; /* Match font size to connect button */
  height: 40px; /* Match height to connect button */
  margin-right: 10px; /* Adjust space between the balance container and the connect button */
}

.connect-wallet {
  background-color: orange;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px; /* Adjust to control the size of the button */
  cursor: pointer;
  font-size: 0.8rem; /* Ensure the font size matches the balance container */
  height: 40px; /* Explicitly define the height to match the balance container */
  display: flex; /* To align text vertically */
  align-items: center; /* Aligns the text vertically in the center */
  justify-content: center; /* Centers the content horizontally */
}

/* Aligns the connect wallet button and balance container side by side */
.wallet-info {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the space between the balance and the button */
}

/* Additional style to ensure spacing is consistent */
.wallet-section {
  display: flex;
  align-items: center;
  gap: 10px; /* Controls the gap between the balance container and connect wallet button */
}


.connect-wallet:hover {
  background-color: #ff8000; /* Darker shade on hover */
}

/* Styles for the hamburger menu icon */
.hamburger {
  display: block; /* The hamburger is always displayed */
  font-size: 24px;
  cursor: pointer;
}


@media (max-width: 768px) {
  /* Top row will contain the hamburger and the logo, spaced between */
  .top-row {
    display: flex;
    justify-content: flex-start; /* Align to the left */
    align-items: center;
    width: 100%; /* Ensure the top row spans the full width */
  }

  .hamburger {
    flex-shrink: 0; /* Prevent the hamburger from shrinking */
  }

  .logo {
    flex-grow: 1; /* Allows the logo to grow and fill available space */
    flex-shrink: 1; /* Allows the logo to shrink if necessary */
    flex-basis: auto; /* Adjusts the initial size of the logo based on its content */
    margin-left: 10px; /* Space between hamburger menu and logo */
    text-align: left; /* Aligns the logo text to the left */
  }

  /* Bottom row will just contain the wallet section */
  .bottom-row {
    justify-content: flex-end; /* Aligns wallet section to the right */
    padding-top: 10px; /* Adds some space between the top and bottom rows */
    flex-wrap: wrap; /* Allows for wrapping if necessary */
  }

  .wallet-section {
    justify-content: flex-end; /* Align wallet section items to the right */
    flex-grow: 0; /* Prevent the wallet section from growing */
    flex-shrink: 0; /* Prevent the wallet section from shrinking */
    margin-left: auto; /* Push the wallet section to the right */
  }

  /* Ensures the connect button and balance are on the right */
  .connect-wallet, .eth-balance-container {
    margin-left: auto;
  }
}



.mobile-menu {
  display: none;
  position: fixed;
  top: 50px; /* Adjust this value so the menu appears just below the hamburger icon */
  left: 0;
  width: 100%; /* Full width on mobile, adjust as needed */
  max-width: 200px; /* Set a max-width for larger screens if desired */
  background: #333;
  z-index: 999;
  transition: transform 0.3s ease;
  transform: translateY(-100%); /* Hide above the top */
  overflow-y: auto; /* In case your menu is longer than the screen */
}

.mobile-menu.show {
  display: block;
  transform: translateY(0%); /* Slide into view */
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-menu ul li {
  padding: 10px;
  border-bottom: 1px solid #FFF;
}

.mobile-menu ul li a {
  color: #FFF;
  text-decoration: none;
  display: block;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
}
