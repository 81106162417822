.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex: 1; /* Takes up all available space */
  padding: 20px; /* Adjust as needed */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo {
  height: auto; /* Adjust as needed */
  pointer-events: none;
  margin: 20px 0; /* Adjust as needed */
}




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
