.nft-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10vh;
  overflow-y: auto; /* Allows content to scroll */
  z-index: 1000; /* Above other content */
  background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent background */
}

.modal-content {
  width: 90%; /* Slightly less than full width for padding */
  margin: 0 auto; /* Center in the available space */
  max-height: 80vh;
  overflow-y: auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}


.modal-header {
  display: flex;
  justify-content: space-between; /* This will push the close button to the left and the other buttons to the right */
  align-items: center;
  width: 100%; /* Ensure the header spans the full width of its container */
  margin-bottom: 8px; /* Space below the header */
}

.modal-header .close-button {
  background-color: white; /* White background */
  color: black; /* Black font color */
  border: none;
  border-radius: 50%; /* Makes it circular */
  font-size: 16px; /* Maintains font size */
  padding: 4px; /* Sufficient padding to make it circular */
  width: 20px; /* Fixed width */
  height: 20px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto; /* Pushes other buttons to the right */
  cursor: pointer;
  /* Add a subtle shadow if you like */

}

.close-button:hover {
  background-color: #e6e6e6; /* Light grey background on hover for some interaction feedback */
  /* Optionally change the color on hover if desired */
}

.selection-controls button:not(.close-button), 
.stake-button:not(.close-button) {
  background-color: #ff4444; /* Neutral color for other buttons */
  color: white;
  border: none;
  border-radius: 4px; /* Slightly rounded corners */
  font-size: 14px; /* Smaller font size for other buttons */
  padding: 10px 20px; /* Larger padding for a more rectangular shape */
  cursor: pointer;
  /* Other styles... */
}

/* To ensure the spacing in the header */
.selection-controls {
  display: flex;
  gap: 10px;
  margin-left: 10px; /* Ensures some space from the close button */
}

.stake-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.nft-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  
  /* No need for max-height here since modal-content controls scrolling */
}

.nft-item {
  background: white;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column; /* Stack image and name vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  border: 2px solid transparent;
  transition: border-color 0.3s; /* Smooth transition for selection */
  
}

.nft-item.selected {
  border-color: blue; /* Highlight color when selected */
}

.checkmark {
  font-size: 0.8rem; /* Adjust the size of the checkmark */
  position: absolute;
  top: 5px;
  right: 5px;
}



.nft-image {
  max-width: 100%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  
}


.nft-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Adjust minmax for desired size */
  gap: 0px;
  color: black;
}

.nft-item {
  background: white;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers children horizontally */
  justify-content: center; /* Centers children vertically */
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.nft-name, .nft-rewards {
  text-align: center;
  margin: 0;
  color: black;
  font-size: 14px; /* Set the font size to 14px, adjust as needed */
}

.nft-item p {
  text-align: center;
  margin: 0;
  color: black;
  font-size: 14px; /* This sets the font size for all <p> within .nft-item */
}

/* Default font size for desktops */
.nft-name, .nft-rewards {
  font-size: 14px; /* Larger font size for desktop */
}

.nft-name {
  font-weight: bold; /* Make the name bold */
}

/* Adjust font size for tablets */
@media (max-width: 768px) {
  .nft-name, .nft-rewards {
    font-size: 12px; /* Slightly smaller font size for tablets */
  }
}

/* Adjust font size for mobile devices */
@media (max-width: 480px) {
  .nft-name, .nft-rewards {
    font-size: 10px; /* Smallest font size for mobile devices */
  }
}

@media (max-width: 768px) {
  .nft-gallery {
    grid-template-columns: repeat(2, 1fr); /* Two columns on mobile */
    gap: 2px; /* Smaller gap on mobile */
    padding: 0 10px; /* Padding inside the modal */
  }

  .nft-item img {
    max-width: 90%; /* Images take up a majority of item space */
    height: auto; /* Maintain aspect ratio */
  }
}